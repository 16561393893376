import * as React from 'react';

import {SanityEditorial, SanityLabelledImage, SanityEditorialHomepage, Maybe, SanityTopic} from 'types/generated';

import ArticlesListTemplate from './index';

export default ({
  pageContext,
}: {
  pageContext: {
    href: string;
    homepage: Array<Maybe<SanityEditorialHomepage>>;
    firstThreeArticles: Maybe<Array<SanityEditorial>>;
    topics: Array<Maybe<SanityTopic>>;
    podcastArticles: [Maybe<SanityEditorial>];
  };
  location: Location;
}): React.ReactElement => (
  <ArticlesListTemplate
    homepage={pageContext.homepage}
    firstThreeArticles={pageContext.firstThreeArticles}
    topics={pageContext.topics}
    podcastArticles={pageContext.podcastArticles}
  />
);
