import React from 'react';

import {useStaticQuery, graphql} from 'gatsby';
import {GridCard, PodcastModal} from 'src/components/editorial';

import {Maybe, SanityEditorial} from 'types/generated';

interface RelatedArticleProps {
  relatedArticles: [Maybe<SanityEditorial>];
  heading: Maybe<string>;
  subcopy: Maybe<string>;
}

export const MoreFromPodcasts: React.FC<RelatedArticleProps> = ({
  relatedArticles,
  heading,
  subcopy,
}: RelatedArticleProps) => {
  const data = useStaticQuery(graphql`
    query MoreFromPodcastsQuery {
      sanityGlobalPodcastList {
        title
        podcastLinks: _rawPodcastLinks(resolveReferences: {maxDepth: 8})
      }
    }
  `);

  const globalPodcastContent = data.sanityGlobalPodcastList;

  const Posts = relatedArticles.map(item => {
    return (
      <GridCard
        cardColClasses={
          'col-span-full md:col-span-6 lg:col-span-3 grid grid-cols-4 gap-x-4 items-center md:items-start content-start'
        }
        title={item?.title}
        category={item?.editorialCategory?.title || ' '}
        image={item?.heroImage !== null ? item?.heroImage : null}
        imageClasses={'col-span-2 md:col-span-full pb-full mt-3 md:mb-6'}
        contentClasses={'col-span-2 md:col-span-full'}
        titleClasses={'type-theta'}
        slug={item?.slug?.current ?? ''}
        key={item?.id}
        eyebrowClasses={'block type-eyebrow mb-2 text-green-dark'}
        editorialCategory={item?.editorialCategory}
        isPodcast={true}
        episodeImage={item?.episodeImage ?? null}
      />
    );
  });
  return (
    <>
      <section className="px-gutter" data-module="more-podcast">
        <div className="wrapper-default grid grid-cols-4 md:grid-cols-12 gap-y-6 md:gap-8 module-spacing">
          <div className="flex flex-col justify-center items-center col-span-full relative">
            <h2 className="type-beta mb-5 md:mb-4 text-green-dark">
              {heading ?? 'Next on ' + (globalPodcastContent?.title ?? 'New Rules of Business Podcast')}
            </h2>
            {!!subcopy && <div className="w-full max-w-[630px] flex justify-center text-center mb-6 ">{subcopy}</div>}
            <PodcastModal globalPodcastContent={globalPodcastContent} />
          </div>
          {Posts}
        </div>
      </section>
    </>
  );
};
