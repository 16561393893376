import React from 'react';

import {EDITORIAL, PODCAST} from 'src/routes/paths';
import {ChiefLogo} from 'src/svgs/chiefLogo';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SponsoredArticleProps = {
  sponsoredArticle: any;
};

export const SponsoredArticleBannerFullWidth: React.FC<SponsoredArticleProps> = ({
  sponsoredArticle,
}: SponsoredArticleProps) => {
  return (
    <div className="bg-green-dark px-gutter relative" data-module="sponsorship-banner">
      <div className="wrapper-default grid grid-cols-4 md:grid-cols-12 !static ml-[calc(-50vw+50%)] w-screen md:w-full md:ml-0">
        <div className="col-span-full md:col-span-6 xl:col-span-4 module-spacing m-auto mb-10 md:mb-0 mt-6 md:mt-0 md:pr-20 px-gutter md:px-0">
          <div className="flex items-center mb-4 md:mb-5">
            <span className="type-lota mx-2 text-light-gold">
              {sponsoredArticle.sponsoredText || 'In partnership with'}
            </span>
            <div>
              {sponsoredArticle.sponsorship?.clientLogo?.asset ? (
                <img className="h-6 fill-white !mt-0 !mb-2" src={sponsoredArticle.sponsorship.clientLogo.asset?.url} />
              ) : (
                <span className="type-lota text-light-gold">{sponsoredArticle.sponsorship.clientName}</span>
              )}
            </div>
          </div>

          <a
            href={`${sponsoredArticle.isPodcast ? PODCAST : EDITORIAL}/${sponsoredArticle.slug?.current}`}
            className="link-text !text-white"
          >
            <h3 className="inline type-delta !text-white ">{sponsoredArticle.title}</h3>
          </a>
        </div>

        <div className="col-span-full md:col-span-6 xl:col-span-8  md:ml-0 md:absolute w-screen md:w-[55vw] xl:w-[63vw] m-w-[100vw] h-full right-0 order-first md:order-last">
          <div className="md:absolute w-full h-full md:h-full">
            {sponsoredArticle.sponsorship?.sponsorshipImageLandscape?.asset?.url ? (
              <img
                className="object-cover w-full h-full"
                src={sponsoredArticle.sponsorship?.sponsorshipImagePortrait?.asset?.url}
                alt={
                  sponsoredArticle.sponsorship.sponsorshipImagePortrait.altText ||
                  sponsoredArticle.sponsorship.sponsorshipImagePortrait.filename
                }
              />
            ) : (
              <div className=" hidden md:flex w-full h-full object-cover bg-black items-center justify-center">
                <ChiefLogo classes={'mb-2 lg:mb-0 h-[18px] lg:w-25 lg:h-[8%] fill-light-gold'} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
