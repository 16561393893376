import React from 'react';

import {EditorialHomepageHero} from 'src/components/editorial';
import {BrandPromoBanner} from 'src/components/editorial/BrandPromoBanner';
import {CategoriesFeatureModule} from 'src/components/editorial/CategoriesFeatureModule';
import {EmailBanner} from 'src/components/editorial/EmailBanner';
import {FullWidthBrandCTA} from 'src/components/editorial/FullWidthBrandCTA';
import {HomeIconGrid} from 'src/components/editorial/HomeIconGrid';
import {SponsoredArticleBannerFullWidth} from 'src/components/editorial/SponsoredArticleBannerFullWidth';
import {ThreeGridArticles} from 'src/components/editorial/ThreeGridArticles';
import {SEOWithQuery} from 'src/components/shared';

import {SanityEditorial, SanityEditorialHomepage, SanityTopic, Maybe} from 'types/generated';

import {MoreFromPodcasts} from '../editorial/MoreFromPodcasts';
import {InsightsNavigation} from '../insightsNavigation';

export interface ArticlesListProps {
  homepage: Array<Maybe<SanityEditorialHomepage>>;
  firstThreeArticles: Maybe<Array<SanityEditorial>>;
  podcastArticles: [Maybe<SanityEditorial>];
  topics: Array<Maybe<SanityTopic>>;
}

const ArticlesListTemplate: React.FC<ArticlesListProps> = ({
  homepage,
  firstThreeArticles,
  topics,
  podcastArticles,
}: ArticlesListProps) => {
  const homepg = homepage[0];

  const pageBuilder = homepage[0]?.homePageBuilder?.map(item => {
    if (item?.__typename === 'SanityThreeGridArticles') {
      return (
        <ThreeGridArticles
          heading={item?.heading}
          articleOne={item?.articleOne}
          articleTwo={item?.articleTwo}
          articleThree={item?.articleThree}
        />
      );
    } else if (item?.__typename === 'SanityEditorialHomeIconGrid') {
      return <HomeIconGrid heading={item?.heading} intro={item?.intro} gridItem={item?.gridItem} />;
    } else if (item?.__typename === 'SanityBrandPromoBanner') {
      return <BrandPromoBanner content={item?.content} title={item?.title} cta={item?.cta} />;
    } else if (item?.__typename === 'SanityFullWidthBrandedCta') {
      return <FullWidthBrandCTA content={item?.content} cta={item?.cta} />;
    } else if (item?.__typename === 'SanityAllCatFeatArticles') {
      if (item?.allCategoriesFeature) {
        return <CategoriesFeatureModule topics={topics} />;
      }
    } else if (item?.__typename === 'SanityEmailSignUp') {
      return (
        <EmailBanner
          id="newsletter-form"
          subCopy={item?.subCopy}
          Heading={item?.Heading}
          customizeEmailSignUp={item?.customizeEmailSignUp}
          fullWidth={true}
          homepage={true}
        />
      );
    } else if (item?.__typename === 'SanitySponsoredArticle') {
      return <SponsoredArticleBannerFullWidth sponsoredArticle={item?.sponsoredArticle} />;
    } else if (item?.__typename === 'SanityRecentPodcasts') {
      return <MoreFromPodcasts relatedArticles={podcastArticles} heading={item.heading} subcopy={item.subCopy} />;
    }
  });

  const featured =
    homepg?.customizeHeroSection == true
      ? homepg?.heroSection?.featuredArticle
      : firstThreeArticles
      ? firstThreeArticles[0]
      : null;
  const second =
    homepg?.customizeHeroSection == true
      ? homepg?.heroSection?.secondArticle
      : firstThreeArticles
      ? firstThreeArticles[1]
      : null;
  const third =
    homepg?.customizeHeroSection == true
      ? homepg?.heroSection?.thirdArticle
      : firstThreeArticles
      ? firstThreeArticles[2]
      : null;
  return (
    <>
      <SEOWithQuery
        title={homepage[0]?.seo?.seoMetaTitle || 'CHIEF Thought Leadership' || undefined}
        description={homepage[0]?.seo?.seoDescription || undefined}
        image={homepage[0]?.seo?.seoImage?.asset?.url || undefined}
      />
      <div className="bg-white pt-8 md:pt-0">
        <InsightsNavigation bgColor={'bg-white'} />
        <EditorialHomepageHero featuredArticle={featured} secondArticle={second} thirdArticle={third} />
        {pageBuilder}
      </div>
    </>
  );
};

export default ArticlesListTemplate;
